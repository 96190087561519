<template>
    <div class="Floater">
        <img :src="`/images/${ icon }.svg`" :alt="icon">
    </div>
</template>

<script>

    export default {
        name: 'Floater',
        props: {
            icon: { type: String, validator: value => {
                return ['new', 'invite'].indexOf(value) !== -1
            }},
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Floater {

        position: relative;

        width: size(Large);
        height: size(Large);

        background-color: color(OldGold);
        box-shadow: 0 5px 15px color(Black, 0.15);

        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

    }

    .Floater img {
        width: 16px;
    }

</style>
