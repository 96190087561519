<template>
    <section id="Wagers">
        <BookSelector :books="managedBooks" :selectedName="profile.books[$route.params.book_id].name" />
        <div class="card card--form" v-if="Object.keys(wagers).length === 0">
            <h3>It's time!</h3>
            <p>It doesn't look like you've created any wagers for this book.</p>
            <p>Press the button below to create your first wager.</p>
            <router-link :to="{ name: 'CreateWager', params: { book_id: $route.params.book_id } }">
                <Button arrow>Create Wager</Button>
            </router-link>
        </div>
        <div class="cards">
            <div class="card" v-for="wager in filteredWagers" :key="wager[0]">
                <div class="content">
                    <div class="name">
                        <div>
                            <h3>{{ wager[1].name }}</h3>
                            <p class="footnote">{{ wager[1].variant }}</p>
                            <p class="footnote course" v-if="wager[1].course">{{ wager[1].course }}</p>
                        </div>
                        <Status :type="getStatus(wager)" />
                    </div>
                    <div class="bets">
                        <h4>Bets Placed</h4>
                        <h4 class="bet-count">{{ wager[1].bets ? Object.keys(wager[1].bets).length : 0 }}</h4>
                    </div>
                </div>
                <router-link :to="{ name: 'EditWager', params: { book_id: $route.params.book_id, wager_id: wager[0] } }">
                    <button class="manage">Manage</button>
                </router-link>
            </div>
        </div>
        <router-link v-if="Object.keys(wagers).length > 0" :to="{ name: 'CreateWager', params: { book_id: $route.params.book_id } }">
            <Floater icon="new" />
        </router-link>
        <Filters v-if="Object.keys(wagers).length > 0" :filters="tags" :selected="filter" @select="changeFilter" />
        <Options :options="options" :selected="1" hideShadow />
    </section>
</template>

<script>

    // Components.
    import BookSelector from '@/components/BookSelector.vue';
    import Options from '@/components/Options.vue';
    import Button from '@/components/Button.vue';
    import Status from '@/components/Status.vue';
    import Floater from '@/components/Floater.vue';
    import Filters from '@/components/Filters.vue';

    // Firebase.
    import { watchBook } from '@/firebase/books.js';
    import { watchWager } from '@/firebase/wagers.js';

    // Misc.
    import { MANAGE_OPTIONS } from '@/constants.js';

    export default {
        name: 'Wagers',
        components: {
            BookSelector,
            Options,
            Button,
            Status,
            Floater,
            Filters,
        },
        data() {
            return {
                wagers: {},
                filter: 'ALL',
            }
        },
        computed: {
            managedBooks() {
                return Object.entries(this.profile.books).filter(book => book[1].membership === 'bookie' || book[1].membership === 'admin');
            },
            options() {
                return MANAGE_OPTIONS.map(option => {
                    return { ...option, params: { book_id: this.$route.params.book_id } };
                });
            },
            tags() {

                let tags = ['ALL'];

                for (let wager of Object.entries(this.wagers)) {
                    if (wager[1].tags) {
                        tags = [...tags, ...wager[1].tags];
                    }
                }

                return [...new Set(tags)];

            },
            filteredWagers() {
                return Object.entries(this.wagers).filter(wager => {

                    if (wager[1].tags) {
                        return wager[1].tags.includes(this.filter) || this.filter === 'ALL';
                    }

                    return this.filter === 'ALL';

                });
            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', true);
        },
        created() {
            this.$store.dispatch('updateLoading', 1);
            watchBook(this.$route.params.book_id).on('value', this.listen);
        },
        watch: {
            $route(to, from) {
                this.$store.dispatch('updateLoading', 1);
                watchBook(from.params.book_id).off('value', this.listen);
                watchBook(to.params.book_id).on('value', this.listen);
            }
        },
        beforeDestroy() {
            watchBook(this.$route.params.book_id).off('value', this.listen);
        },
        methods: {
            listen(snapshot) {

                const val = snapshot.val();
                this.wagers = {};

                if (val.wagers) {
                    for (let wager of Object.keys(val.wagers)) {
                        this.$store.dispatch('updateLoading', 1);
                        watchWager(wager).on('value', this.watchWager);
                    }
                }

                this.$store.dispatch('updateLoading', -1);

            },
            watchWager(snapshot) {

                const key = snapshot.key;
                const val = snapshot.val();

                this.wagers = { ...this.wagers, [key]: val };

                this.$store.dispatch('updateLoading', -1);

            },
            changeFilter(filter) {
                this.filter = filter;
            },
            getStatus(wager) {

                if (wager[1].status === 'open' && Date.now() > wager[1].startTime) {
                    return 'pending';
                }

                return wager[1].status;

            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #Wagers {
        padding: size(Large) 0 50px;
    }

    .card--form {
        margin: size(Medium) auto;
    }

    .card--form p {
        margin-top: size(Small);
    }

    .card--form .Button {
        margin-top: size(Large);
    }

    .card:not(.card--form):first-of-type {
        margin-top: size(Medium);
    }

    .card:not(.card--form) {
        margin-top: size(Small);
    }

    .card:not(.card--form):last-of-type {
        margin-bottom: (50px + size(Large) + size(Medium) + size(Small));
    }

    .content {
        padding: size(Medium);
    }

    .name, .bets {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .name {
        padding-bottom: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .name p.footnote {
        font-weight: 700;
        // margin-top: 2px;
    }

    .name p.footnote:first-of-type {
        margin-top: size(Micro);
    }

    .bets {
        padding-top: size(Medium);
    }

    .bet-count {
        color: color(Emperor, 0.25);
    }

    button.manage {

        appearance: none;
        outline: none;
        border: none;

        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: color(OldGold);
        text-transform: uppercase;

        background: color(White);

        width: 100%;
        padding: size(Medium);

        display: flex;
        align-items: center;
        justify-content: center;

        border-top: 1px dashed color(Emperor, 0.15);

    }

    .Floater {
        position: fixed !important;
        bottom: size(Medium) + size(Large) + 50px;
        right: size(Medium);
        z-index: 5;
    }

    .Filters {
        position: fixed !important;
        bottom: 50px;
        left: 0;
        z-index: 10;
    }

</style>
